<template>
  <div>
    <!-- 上传答题卡弹窗，在制作模板答题卡页面使用 -->
    <el-dialog title="上传文件"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form label-width="120px">
        <el-row :gutter="10"
                v-if="fileType!=1">
          <el-col :span="24">
            <el-form-item label="选择Excel">
              <el-button type="primary"
                         style="padding:10px 30px"
                         @click="importExcel"
                         :disabled="!!fileList.word"
                         v-if="!fileList.excel">选择</el-button>
              <!-- <el-tag @close="clear"
                    v-else
                    closable
                    type="priamry">
              {{file.name?file.name:''}}
            </el-tag> -->
              <el-tag @close="clear('excel')"
                      closable
                      type="priamry"
                      v-else>{{fileList.excel?fileList.excel.name:''}}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="选择Word"
                          v-if="fileType!=2">
              <el-button type="primary"
                         style="padding:10px 30px"
                         @click="importWord"
                         :disabled="!!fileList.excel"
                         v-if="!fileList.word">选择</el-button>
              <!-- <el-tag @close="clear"
                    v-else
                    closable
                    type="priamry">
              {{file.name?file.name:''}}
            </el-tag> -->
              <el-tag @close="clear('word')"
                      closable
                      type="priamry"
                      v-else>{{fileList.word?fileList.word.name:''}}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="选择PDF">
              <el-button type="primary"
                         style="padding:10px 30px"
                         @click="importPDF"
                         v-if="!fileList.pdf">选择</el-button>
              <!-- <el-tag @close="clear"
                    v-else
                    closable
                    type="priamry">
              {{file.name?file.name:''}}
            </el-tag> -->
              <el-tag @close="clear('pdf')"
                      closable
                      type="priamry"
                      v-else>{{fileList.pdf?fileList.pdf.name:''}}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10"
                style="text-align:center">
          <el-col :span="24">
            <el-button type="primary"
                       @click="submit">提交</el-button>
          </el-col>
        </el-row>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
import getFile from '@/utils/file.js'

export default {
  data () {

    return {
      dialogVisible: false,
      fileList: {
        word: undefined,
        excel: undefined,
        pdf: undefined
      },
      fileType: 0 //0:word,pdf,excel  1:word,pdf  2:excel,pdf
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    submit () {
      if (!this.fileList.word && !this.fileList.excel) {
        this.$message.warning("请选择excel文件或word文件")
        return
      }
      if (!this.fileList.pdf) {
        this.$message.warning("请选择pdf文件")
        return
      }
      this.$emit('importSubmit', this.fileList)
    },
    clear (type) {
      this.fileList[type] = undefined
    },
    async importWord (str) {
      let limit = 200
      let fileType = "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      const res = await getFile({ limit, fileType })
      console.log(res, "res")
      this.fileList.word = res
      console.log(this.fileList)
      // let formData = new FormData()
    },
    async importExcel (str) {
      let limit = 200
      let fileType = "excel,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv"
      const res = await getFile({ limit, fileType })
      console.log(res, "res")
      this.fileList.excel = res
      console.log(this.fileList)
      // let formData = new FormData()
    },
    async importPDF (str) {
      let limit = 200
      let fileType = ".pdf"
      const res = await getFile({ limit, fileType })
      console.log(res, "res")
      this.fileList.pdf = res
      console.log(this.fileList)
      // let formData = new FormData()
    },

    reset () {
      this.fileList = {
        word: undefined,
        excel: undefined,
        pdf: undefined
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hide_label {
  ::v-deep .el-radio__label {
    display: none;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>